export default {
  siteTitle: 'Get bonus',
  blogerName: 'Сasalinsano',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://youtube.com/@casal_insano?si=9y1NORRTYzaKEiRs'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/casal_insanoofc/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/cb6468e04',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c3260997c',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/ca2bc5d81',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cd02eeae5',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c1e6d7e28',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c14346c47',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cb62795e8',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cadeeb0c3',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cd2260b87',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>INSAN</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'INSAN',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
